@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";

@layer components {
  /* .my-react-select-container {
  } */
  .my-react-select-container .my-react-select__control {
    @apply border border-main-gray-600 bg-white hover:border-neutral-400 dark:border-gray-300 
     dark:bg-transparent dark:hover:border-neutral-500;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply border-neutral-500 shadow-none hover:border-neutral-500 dark:border-neutral-400 dark:hover:border-neutral-400;
  }

  .my-react-select-container .my-react-select__menu {
    @apply border border-neutral-300 bg-neutral-100 dark:border-neutral-600 dark:bg-neutral-700;
  }

  .my-react-select-container .my-react-select__option {
    @apply bg-neutral-100 text-neutral-600 hover:bg-neutral-200 dark:bg-neutral-700 dark:text-neutral-200 dark:hover:bg-neutral-800;
  }
  .my-react-select-container
    .my-react-select__multi-value__label
    .my-react-select__multi-value__remove {
    @apply bg-main-gray-btnHover text-main-blue-subMain;
  }
  /* .my-react-select-container .my-react-select__option--is-focused {
    @apply bg-neutral-200 dark:bg-neutral-800;
  } */

  .my-react-select-container .my-react-select__indicator-separator {
    @apply bg-neutral-400;
  }
  .my-react-select-container .my-react-select__single-value {
    @apply mr-auto items-start justify-start font-robot-mono  text-[14px] font-bold uppercase leading-normal tracking-[0.15rem] text-main-gray-600;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-neutral-600 dark:text-neutral-200;
  }
}

@layer utilities {
  .layout-custom-shadow {
    box-shadow: inset 0 40px 50px 0 rgba(14, 36, 65, 1);
  }

  .custom-sidebar-shadow {
    box-shadow:
      inset 0 10px 23px 0 rgba(36, 80, 130, 0.4),
      0 20px 60px 0 rgba(0, 119, 255, 0.1);
  }
  .custom-btn-drop-shadow {
    box-shadow: 0 2px 5px 0 rgba(38, 51, 77, 0.03);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
