.switch-theme-container {
}

/* .switch-theme-container button span {
  visibility: hidden;
} */
.animated-button-sidebar {
  padding-left: 0 !important;
  padding-right: 0 !important;
  justify-content: center;
  justify-items: center;

  border-radius: 20px;
  position: relative;
  background: transparent;
  color: #0a1030;
  text-decoration: none;
  border: none;
  font-size: 1.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  transition: 0.2s;
  min-width: max-content;
  border: 2px solid transparent;
  letter-spacing: 0.1rem;
  font-weight: 600;
}

.animated-button-sidebar:hover {
  border-bottom: 2px solid #16a34a;
  color: #16a34a;
  /* box-shadow: 0 0 35px #16a34a; */
  animation: box 2s infinite;
}

.animated-button-sidebar::before {
  border-radius: 20px;
  content: "";
  position: absolute;
  inset: 2px;
  background: transparent;
}

.animated-button-sidebar span {
  position: relative;
  z-index: 1;
}
.animated-button-sidebar i {
  position: absolute;
  inset: 0;
  display: block;
}

.animated-button-sidebar i::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  left: 80%;
  top: -4px;
  background: transparent;
  transition: 0.2s;
  box-sizing: unset;
}

.animated-button-sidebar:hover i::before {
  width: 15px;
  left: 20%;
  animation: move 3s infinite;
  background: #16a34a;
  border: 2px solid #16a34a;
}

.animated-button-sidebar i::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  left: 20%;
  bottom: -4px;
  background: transparent;
  transition: 0.2s;
  box-sizing: unset;
}

.animated-button-sidebar:hover i::after {
  width: 15px;
  left: 80%;
  background: #16a34a;
  animation: move 3s infinite;
  border: 2px solid #16a34a;
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes box {
  0% {
    box-shadow: #000000;
  }
  50% {
    box-shadow: 0 0 25px #16a34a;
  }
  100% {
    box-shadow: #000000;
  }
}

.animate-charcter {
  /* text-transform: uppercase; */
  background-image: linear-gradient(
    -225deg,
    #ab2fb7 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: xx-large;
  /* font-size: 190px; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
